export interface MediaList {
  date: string;
  name: string;
  location: string;
  title: string;
  url: string;
}

export default [
  {
    date: "2018-10-4",
    name: "2018-10-4 - Light clients.png",
    title: "Why Light clients are the future",
    location: "Meetup in Berlin, Germany",
    url: "https://www.youtube.com/watch?v=rowrrffglSI",
  },
  {
    date: "2018-11-20",
    location: "Meetup in Cambridge, UK",
    name: "2018-11-20 - Light client Cambridge.png",
    title: "What is a light client",
    url: "https://www.youtube.com/watch?v=0vzrfCruvK8",
  },
  {
    date: "2018-12-12",
    location: "Hyve mind in Berlin, Germany",
    name: "2018-12-12 - Polkadot & Substrate Hyve mind.png",
    title: "Polkadot & Substrate",
    url: "https://www.youtube.com/watch?v=bo4TM9GjQ64&t=1180s",
  },
  {
    date: "2018-12-25",
    location: "Meetup in Berlin, Germany",
    name: "2018-12-25 - Polkadot & Parity Substrate.png",
    title: "Polkadot & Parity Substrate",
    url: "https://www.youtube.com/watch?v=T2xJq-_J1YE",
  },
  {
    date: "2019-3-10",
    location: "EthCC 2 in Paris, France",
    name: "2019-3-10 EthCC 2 - Parity Signer.png",
    title: "Parity Signer",
    url: "https://www.youtube.com/watch?v=EEc8cpcHkNo",
  },
  {
    date: "2019-11-05",
    location: "Ukiyo conference in Osaka, Japan",
    name: "2019-11-05 - Ukiyo Osaka - WFP.png",
    title: "How a blockchain tech helps refugees",
    url: "https://www.youtube.com/watch?v=tXOXxwpAIGk",
  },
  {
    date: "2019-11-21",
    location: "Devcon 5 in Osaka, Japan",
    name: "2019-11-21 Devcon 5 - Signer.png",
    title: "Parity Signer",
    url: "https://www.youtube.com/watch?v=3voHc-2d-GE",
  },
  {
    date: "2019-11-21",
    location: "This week in crypto in Osaka, Japan",
    name: "2019-11-21 - This week in crypto - Signer.png",
    title: "Parity Signer",
    url: "https://www.youtube.com/watch?v=gpNYUe_JKOg",
  },
  {
    date: "2019-12-8",
    location: "Devcon 5 in Osaka, Japan",
    name: "2019-12-8 - Devcon 5 - light client Fether.png",
    title: "Building Fether using a light client",
    url: "https://www.youtube.com/watch?v=di3WfuKWFsc",
  },
  {
    date: "2019-12-27",
    location: "Devcon 5 in Osaka, Japan",
    name: "2019-12-27 - Devcon 5 Japan Light client Fether.png",
    title: "The UX challenges to build on top of a light client",
    url: "https://www.youtube.com/watch?v=K0aC9d83euI",
  },
  {
    date: "2020-08-25",
    name: "2020-08-25 - Polkadot governance polkassembly.png",
    title: "Deep dive into Polkadot governance",
    location: "Online meetup",
    url: "https://tube.piweb.be/videos/watch/acc2590f-99c8-446b-a872-0a3943cf752d",
  },
  {
    date: "2021-01-21",
    name: "2021-01-21 - Manage account Polkadot js extension.png",
    title: "How to securely manage your account with Polkadot-js extension",
    location: "Online meetup",
    url: "https://tube.piweb.be/videos/watch/aa4e8b2b-a58d-49cb-abec-02a3e714ed4a",
  },
  {
    date: "2022-04-29",
    name: "2021-04-29 - Keep funds safe offline.png",
    title: "How to securely manage your account with Polkadot-js extension",
    location: "Amsterdot in Amsterdam, The Netherland",
    url: "https://www.youtube.com/watch?v=FlAYT9uOaXU",
  },
  {
    date: "2023-06-29",
    name: "2023-06-29 - How to manage complex multisigs.jpg",
    title: "Multisigs made simple",
    location: "Polkadot Decoded in Copenhagen, Danmark",
    url: "https://www.youtube.com/watch?v=cQymHtreDUA",
  },
  {
    date: "2023-09-19",
    name: "2023-09-19 - Use a Multisig to do x.jpg",
    title: "Multix - use a Multisig to do x",
    location: "Sub0 in Lisbon, Portugal",
    url: "https://www.youtube.com/watch?v=rmBhErBOVJM",
  },
] as MediaList[];
